/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-02",
    versionChannel: "nightly",
    buildDate: "2024-09-02T00:06:07.844Z",
    commitHash: "a889bd85e5b59c5a2eb85fe8a8e9a776c9e78a62",
};
